
  .dropzone-file {
    text-align: center;
    padding: 20px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
  
    margin-bottom: 20px;
  }
  