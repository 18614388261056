.csv .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #183247!important;
    background-color: #e9f7ff!important;
     }
      body{
        overflow-x: hidden!important;
      }

      .settings a:hover, .settings a.active{
        background-color:#f1f2fe;
      }
      ::-webkit-scrollbar {
     width: 6px;
     }

    ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    }

    ::-webkit-scrollbar-thumb {
    background: #888; 
    }