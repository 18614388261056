body{
    overflow-x: hidden!important;
  }
  .tags .form-control:focus {
  border-color: #dee2e6;
  }
  .hover-btns span{
   display: none;
   top: 10px;
   right: 10px;
   }
   .hover-btns{
   top: 10px;
   right: 10px;
   }
   .show-btns:hover .hover-btns span{
   display:flex;
   justify-content: flex-end;
   }
  .settings a:hover, .settings a.active{
    background-color:#f1f2fe;
  }
  ::-webkit-scrollbar {
 width: 6px;
 }

::-webkit-scrollbar-track {
background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
background: #888; 
}
