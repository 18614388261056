.modal {
   overflow-y: auto!important;
       background-color: rgba(49, 58, 70,.5);
  }

   /* .page-aside-left {
  width: inherit!important;
  padding:20px!important;
   } */
   .stages {
   margin: 0;
   padding: 0;
   display:block;
   top: 3px;
   position: relative;
   font-size: 0;
   }
   .stages.candidature li, .stages.candidature li.active{
   background-color: #8b9a9f;
   }
   .stages.screening li, .stages.screening li.active{
   background-color: #ffb700;
   }
   .stages li {
   width: 12px;
   height: 12px;
   text-align: center;
   border-radius: 50%;
   display: inline-block;
   color: #fff;
   position: relative;
   font-weight: 600;
   -webkit-transition: all 1s ease-in-out;
   -o-transition: all 1s ease-in-out;
   transition: all 1s ease-in-out;
   }
   .stages li:not(:first-child) {
   margin-left: 3px;
   }
   .stages li:not(:last-child) {
   margin-right: 3px;
   }
   .stages li span {
   font-size: 8px;
   line-height: 12px;
   }
   .stages .active~li::before {
   background-color: #e9e9e9;
   -webkit-box-shadow: #989898 0 1px 0 0 inset;
   box-shadow: #989898 0 1px 0 0 inset;
   }
   .stages .active~li {
   background-color: #e9e9e9;
   color: #e9e9e9;
   -webkit-box-shadow: #989898 0 1px 1px 0 inset;
   box-shadow: #989898 0 1px 1px 0 inset;
   }
   .stages li::before {
   content: '';
   position: absolute;
   top: 5px;
   left: -5px;
   width: 6px;
   height: 3px;
   z-index: 0;
   }
   .stages.candidature+.stage-details {
   background-color: #ecf0f3;
   color: #343f50;
   }
   .stages+.stage-details {
   padding: 8px 25px;
   border-radius: 25px;
   text-align: left;
   font-size: 12px;
   position: relative;
   margin-top: 15px;
   }
   .stages+.stage-details .stage-details-arrow {
   border-bottom: 10px solid;
   border-left: 10px solid transparent;
   border-right: 10px solid transparent;
   display: inline-block;
   position: absolute;
   top: -10px;
   left: calc(50% - 46px);
   color:#ecf0f3;
   }

   .absolute-Icon{
   top: 0px;
   right: 0px;
   }
   #user-modal input:placeholder-shown+label {
   cursor: text;
   width: calc(50%)!important;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   transform-origin: left bottom;
   background-color: #fff;
   margin: 0px 7px;
   padding: 0px 4px;
   font-weight: 500;
   }
   #user-modal input:not(:placeholder-shown)+label {
   top: -9px!important;
   color: #183247;
   font-weight: 500;
   }

   #experience-modal .field input:placeholder-shown+label {
   cursor: text;
   width: calc(50%)!important;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   transform-origin: left bottom;
   background-color: #fff;
   margin: 0px 7px;
   padding: 0px 4px;
   font-weight: 500;
   }
   #experience-modal .field input:not(:placeholder-shown)+label {
   top: -9px!important;
   color: #183247;
   font-weight: 500;
   }
   #application-modal .field input:placeholder-shown+label {
   cursor: text;
   width: calc(50%)!important;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   transform-origin: left bottom;
   background-color: #fff;
   margin: 0px 7px;
   padding: 0px 4px;
   font-weight: 500;
   }
   #application-modal .field input:not(:placeholder-shown)+label {
   top: -9px!important;
   color: #183247;
   font-weight: 500;
   }
   #education-modal .field input:placeholder-shown+label {
   cursor: text;
   width: calc(50%)!important;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   transform-origin: left bottom;
   background-color: #fff;
   margin: 0px 7px;
   padding: 0px 4px;
   font-weight: 500;
   }
   #education-modal .field input:not(:placeholder-shown)+label {
   top: -9px!important;
   color: #183247;
   font-weight: 500;
   }
   .hover-btns button{
   display: none;
   top: 10px;
   right: 10px;
   }
   .hover-btns{
   top: 10px;
   right: 10px;
   }
   .show-btns:hover .hover-btns button{
   display:flex;
   justify-content: center;
   }
   .hover-btns:hover{
   background-color: rgba(255, 255, 255, 0.5);
   }
   .profile-tabs .nav-pills .nav-link.active{
   color: #ff5969 !important;
   background-color: #fff !important;
   height: 51px;
   align-items: center;
   display: flex;
   }
   .interview-tabs .nav-pills .nav-link.active{
   color: #ff5969 !important;
   background-color: transparent!important;
   height: 51px;
   align-items: center;
   display: flex;
   }
   .follow-bell:hover{
    color:#0063b8; 
   }
   .comma-right:not(:last-child)::after {
   content: ', ';
   }

   #Comments .show-btns .hover-btns button:nth-last-child(2){
    display: flex;
   }
    .candidate-info:hover .display-information{
   z-index: 9999;
   }
   .btn-dashed{
      border:1px dashed #2ca1db!important;
      color: #2ca1db;
   }
   .bg-alert{
      background: linear-gradient(
         0deg,#fff 0,#f8f9fa 100%);
   }

   .overflow-conversations{
      overflow-y: auto;
      height: 90vh;
   }
 ::-webkit-scrollbar {
 width: 6px;
 }

::-webkit-scrollbar-track {
background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
background: #888; 
}
.vertical-timeline {
width: 100%;
position: relative;
padding: 1.5rem 0 1rem
}

.vertical-timeline::before {
content: '';
position: absolute;
top: 0;
left: 67px;
height: 100%;
width: 4px;
background: #e9ecef;
border-radius: .25rem
}

.vertical-timeline-element {
position: relative;
margin: 0 0 1rem
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
visibility: visible;
animation: cd-bounce-1 .8s
}

.vertical-timeline-element-icon {
position: absolute;
top: 0;
left: 53px
}

.vertical-timeline-element-content {
position: relative;
margin-left: 94px;
font-size: .8rem
}

.vertical-timeline-element-content .timeline-title {
font-size: .8rem;
text-transform: uppercase;
margin: 0 0 .5rem;
padding: 2px 0 0;
font-weight: bold
}

.vertical-timeline-element-content .vertical-timeline-element-date {
display: block;
position: absolute;
left: -104px;
top: 0;
padding-right: 10px;
text-align: right;
color: #adb5bd;
font-size: .7619rem;
white-space: nowrap
}

.vertical-timeline-element-content:after {
content: "";
display: table;
clear: both
}


   .table-custom thead .form-check-input[type="checkbox"] {
   border-radius: 50%!important;
   border-color: #34a6b0!important;
   background-color: #34a6b0!important;
   width:20px;
   height:20px;
   }
   .table-custom tbody .form-check-input[type="checkbox"] {
   border-radius: 50%!important;
   width:20px;
   height:20px;
   }
   .candidates .page-item.active .page-link {
   background-color: #183247!important;
   border-color: #183247!important;
   }
   .candidates .page-item.active .page-link:hover {
   background-color: #f3f3f3!important;
   border-color: #183247!important;
   color:#183247!important;
   }

   .custom-select{
      width: 100px !important;
   }