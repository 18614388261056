.table-condensed{
    border-spacing: 0 10px;
   border-collapse: inherit;
    }

  .text-skyblue{
    color:#0070d0;
  }

   .table-custom th{
   overflow: hidden;
   background: #3ec5d1;
   color: #fff;
   font-weight: 600;
   padding:7px 15px;
   vertical-align: middle;
   }
   
  .table-custom td{
  padding:15px;
  vertical-align: middle;
   }

  .candidates-row:hover{
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 20%)!important;
  }

  .td-border-left::before{
       content: '';
position: absolute;
top: 4px;
bottom: 4px;
left: 4px;
width: 2px;
background-color: #7fc137;
  }

.mdi-dots-vertical.dropdown-toggle::after{
content:none!important;
}

.custom .dropdown-header{
padding: 0.5rem 1.1rem!important;
}

.custom.dropdown-menu{
padding: 10px 0;
max-height: 400px!important;
min-width: 210px!important;
overflow: auto!important;
border: 0;
-webkit-box-shadow: 0 0 4px 0 #bbb;
box-shadow: 0 0 4px 0 #bbb;
}

.table-custom thead .form-check-input[type="checkbox"] {
border-radius: 50%!important;
border-color: #34a6b0!important;
background-color: #34a6b0!important;
width:20px;
height:20px;
}
.table-custom tbody .form-check-input[type="checkbox"] {
border-radius: 50%!important;
width:20px;
height:20px;
}
